<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="color"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M39.5434 16.1776C39.557 16.5273 39.5615 16.877 39.5615 17.2267C39.5615 27.8311 32.1018 40.0733 18.4606 40.0733C14.2703 40.0733 10.3739 38.7378 7.09097 36.4643C7.67129 36.5279 8.26065 36.5757 8.85906 36.5757C12.3334 36.5757 15.5334 35.2876 18.0717 33.1254C14.8265 33.0777 12.0862 30.7406 11.1411 27.5609C11.5948 27.6563 12.0621 27.7041 12.5399 27.7041C13.2137 27.7041 13.8678 27.6088 14.4949 27.418C11.0989 26.6867 8.54102 23.4434 8.54102 19.5483C8.54102 19.5006 8.54102 19.4846 8.54102 19.4528C9.54187 20.041 10.6874 20.4065 11.9038 20.4542C9.91114 19.0074 8.60127 16.5432 8.60127 13.7609C8.60127 12.2983 8.96604 10.915 9.60816 9.72262C13.2649 14.5876 18.732 17.7833 24.8954 18.1172C24.7687 17.5289 24.7039 16.909 24.7039 16.289C24.7039 11.8532 28.0245 8.26007 32.1214 8.26007C34.2542 8.26007 36.1806 9.23003 37.5326 10.7881C39.2253 10.4383 40.811 9.77063 42.246 8.84851C41.6898 10.7245 40.5156 12.2982 38.9811 13.2839C40.4824 13.0931 41.9144 12.6642 43.2424 12.0282C42.246 13.634 40.9919 15.0488 39.5434 16.1776Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "white",
    },
    size: {
      type: Number,
      default: 48,
    },
  },
};
</script>
