<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="color"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.6424 5.9168C30.1 6.13588 34.2536 7.97407 37.4261 11.1487C40.8101 14.5348 42.6728 19.0359 42.671 23.8226C42.6669 33.7003 34.6256 41.7373 24.7473 41.7373C21.0325 41.7373 18.0805 40.596 16.1744 39.5568L6.67273 42.048L9.21561 32.7646C7.64708 30.0477 6.82173 26.9659 6.82302 23.8084C6.82699 13.9309 14.8676 5.89465 24.747 5.89465L25.6424 5.9168ZM16.6146 36.3136L17.1587 36.6362C19.446 37.9929 22.0679 38.7108 24.7411 38.7118H24.7472C32.9584 38.7118 39.6413 32.0319 39.6446 23.8216C39.6462 19.8429 38.0981 16.1018 35.2854 13.2873C32.4726 10.4727 28.7323 8.92174 24.7531 8.92045C16.5356 8.92045 9.85256 15.5996 9.84928 23.8094C9.84816 26.623 10.6357 29.3631 12.127 31.7337L12.4812 32.2972L10.9762 37.7919L16.6146 36.3136ZM33.7771 28.0827C33.6651 27.8958 33.3666 27.7839 32.9188 27.5598C32.4709 27.3357 30.2691 26.2528 29.8585 26.1033C29.448 25.9541 29.1494 25.8795 28.8509 26.3275C28.5524 26.7755 27.6941 27.7839 27.4328 28.0827C27.1716 28.3814 26.9103 28.4188 26.4625 28.1947C26.0147 27.9707 24.5716 27.4979 22.861 25.9729C21.5296 24.7859 20.6308 23.3201 20.3695 22.872C20.1083 22.4239 20.3416 22.1816 20.5658 21.9584C20.7673 21.7578 21.0138 21.4356 21.2377 21.1742C21.4616 20.9129 21.5362 20.726 21.6856 20.4274C21.8348 20.1286 21.7601 19.8673 21.6482 19.6432C21.5362 19.4191 20.6406 17.2157 20.2674 16.3194C19.9038 15.4466 19.5346 15.5648 19.2597 15.551C18.9988 15.5381 18.6999 15.5352 18.4014 15.5352C18.1028 15.5352 17.6176 15.6472 17.2071 16.0954C16.7966 16.5435 15.6396 17.6266 15.6396 19.8297C15.6396 22.0332 17.2444 24.1615 17.4683 24.4603C17.6923 24.7592 20.6264 29.2804 25.119 31.2195C26.1875 31.6807 27.0217 31.9561 27.6721 32.1624C28.745 32.5031 29.7213 32.4551 30.493 32.3398C31.3534 32.2113 33.1427 31.2569 33.5159 30.2114C33.8891 29.1656 33.8891 28.2694 33.7771 28.0827Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "white",
    },
    size: {
      type: Number,
      default: 48,
    },
  },
};
</script>
